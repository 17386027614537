import PageDiv from "../PageDiv";
import { authContext } from "../../context/auth";

import { useState, useContext, useEffect } from "react";
import SmallSpinner from "../SmallSpinner";


function GenerateSqlPage() {
    const {user, token} = useContext(authContext);
    const [snowflakeDatabases, setSnowflakeDatabases] = useState([]);
    const [loadingSnowflakeDatabases, setLoadingSnowflakeDatabases] = useState(false);
    const [currentSnowflakeDatabase, setCurrentSnowflakeDatabase] = useState(null);
    const [errorChangeDatabase, setErrorChangeDatabase] = useState("");
    const [errorGenerateSQL, setErrorGenerateSQL] = useState("");
    const [errorSubmit, setErrorSubmit] = useState("");
    const [nrQueries, setNrQueries] = useState(null);
    const [generatingSql, setGeneratingSql] = useState(false);
    const [submittingScore, setSubmittingScore] = useState(false);
    const [loadingNrQueries, setLoadingNrQueries] = useState(false);
    const [naturalLanguageText, setNaturalLanguageText] = useState("");
    const [naturalLanguageTextWarning, setNaturalLanguageTextWarning] = useState("");
    const [naturalLanguageTextForEvaluation, setNaturalLanguageTextForEvaluation] = useState("");
    const [sqlGenerated, setSqlGenerated] = useState("");
    const [tweakedSQL, setTweakedSQL] = useState("");
    const [copiedSqlGenerated, setCopiedSqlGenerated] = useState(false);
    const [copiedTweakedSql, setCopiedTweakedSql] = useState(false);


    function copySqlGeneratedToClipboard(text) {
        navigator.clipboard.writeText(text)
        .then(() => setCopiedSqlGenerated(true))
        .catch((err) => console.error(err));

        setTimeout(() => {
            setCopiedSqlGenerated(false);
        }, 1000);
    }

    function copyTweakedSqlToClipboard(text) {
        navigator.clipboard.writeText(text)
        .then(() => setCopiedTweakedSql(true))
        .catch((err) => console.error(err));

        setTimeout(() => {
            setCopiedTweakedSql(false);
        }, 1000);
    }

    async function getNrQueries() {
        setLoadingNrQueries(true);
        const response = await fetch(`${process.env.REACT_APP_endPoint}/text2sqlConversions/count`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        if (response.status !== 200) {
            setNrQueries("Failed to fetch the number of queries already done.");
        }
        else {
            const result = await response.json();
            setNrQueries(result.count);
        }
        setLoadingNrQueries(false);
    }

    async function getSnowflakeDatabases() {
        setLoadingSnowflakeDatabases(true);
        const response = await fetch(`${process.env.REACT_APP_endPoint}/generateSQL/snowflakeDatabases`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        if (response.status !== 200) {
            setSnowflakeDatabases("Failed to fetch the databases.");
        }
        else {
            const result = await response.json();
            console.log(result);
            setSnowflakeDatabases(result.databases);
            setCurrentSnowflakeDatabase(result.databases.find((element) => element.id === user.snowflakeDatabaseId));
        }
        setLoadingSnowflakeDatabases(false);
    }

    useEffect(() => {
        getNrQueries();
        getSnowflakeDatabases();
    }, []);

    async function generateSql(evt) {
        evt.preventDefault();
        setGeneratingSql(true);
        setErrorGenerateSQL("");
        setNaturalLanguageTextWarning("");
        setSqlGenerated("");
        setTweakedSQL("");
        const response = await fetch(`${process.env.REACT_APP_endPoint}/generateSQL`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({
                database: currentSnowflakeDatabase.name,
                schema: currentSnowflakeDatabase.schema,
                naturalLanguageText: naturalLanguageText
            })
        });
        
        if (response.status === 500) {
            setErrorGenerateSQL("Error while generating SQL code");
        }
        else {
            const jsonData = await response.json();
            setNaturalLanguageTextForEvaluation(naturalLanguageText);
            setNaturalLanguageTextWarning(jsonData.naturalLanguageTextWarning);
            setSqlGenerated(jsonData.sql);
        }
        setGeneratingSql(false);
    }

    async function submitScore(evt) {
        evt.preventDefault();
        setErrorSubmit("");

        if (sqlGenerated === tweakedSQL) {
            setErrorSubmit("Tweaked SQL is the same as the generated SQL!");
        }
        else {
            setSubmittingScore(true);
            const response = await fetch(`${process.env.REACT_APP_endPoint}/text2sqlConversions`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    text2sqlConversion: {
                        database: currentSnowflakeDatabase.name,
                        schema: currentSnowflakeDatabase.schema,
                        naturalLanguageText: naturalLanguageTextForEvaluation,
                        sqlGenerated: sqlGenerated,
                        humanEditedSql: tweakedSQL
                    },
                    naturalLanguageTextWarning: naturalLanguageTextWarning
                })
            });

            if (response.status === 200) {
                const jsonData = await response.json();
                console.log(jsonData);
                setNaturalLanguageTextWarning("");
                setSqlGenerated("");
                setNaturalLanguageText("");
                setTweakedSQL("");
            }
            else if (response.status === 400) {
                const jsonData = await response.json();
                setErrorSubmit(jsonData.error);
            }
            else {
                setErrorSubmit(`Error while submitting the result. Server responded with status code ${response.status}`);
            }

            setSubmittingScore(false);
            getNrQueries();
        }
    }

    async function onChange(evt) {
        if (evt.target.id === "naturalLanguageText") {
            setNaturalLanguageText(evt.target.value);
        }
        if (evt.target.id === "generatedSql") {
            setSqlGenerated(evt.target.value);
        }
        if (evt.target.id === "tweakedSQL") {
            setTweakedSQL(evt.target.value);
        }
        if (evt.target.id === "snowflakeDatabase") {
            const currentSelectedDatabase = snowflakeDatabases.find((db) => db.id.toString() === evt.target.value.toString());

            setLoadingSnowflakeDatabases(true);
            const response = await fetch(`${process.env.REACT_APP_endPoint}/generateSQL/changeDB`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    snowflakeDatabaseId: currentSelectedDatabase.id
                })
            });

            if (response.status === 200) {
                const jsonData = await response.json();
                console.log(jsonData);
                setCurrentSnowflakeDatabase(currentSelectedDatabase);
            }
            else {
                setErrorChangeDatabase(`Error while changing the database. Server responded with status code ${response.status}`);
            }
            setLoadingSnowflakeDatabases(false);
        }
    }

    return (
        <PageDiv>
            <form onSubmit={submitScore}>
                <div className={"mb-5"}>
                    <table className="table">
                        <tbody>
                            <tr>
                                <td>User</td>
                                <td>{`${user.firstName} ${user.lastName}`}</td>
                            </tr>
                            <tr>
                                <td>Snowflake database</td>
                                <td>
                                    {loadingSnowflakeDatabases ? <SmallSpinner /> :
                                    <>
                                        <select id={"snowflakeDatabase"} className={"form-select"} value={currentSnowflakeDatabase ? currentSnowflakeDatabase.id : ""} required={true} onChange={onChange}>
                                            <option value="">Select a database</option>
                                            {snowflakeDatabases.map((element) => {
                                                return <option key={element.id} value={element.id}>{`${element.name}.${element.schema}`}</option>;
                                            })}
                                        </select>
                                        <p className={"text-danger"} hidden={errorChangeDatabase === ""}>{errorChangeDatabase}</p>
                                    </>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>Number of queries already submitted</td>
                                <td>{loadingNrQueries && <SmallSpinner/>}{nrQueries}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={"mb-3"}>
                    <label htmlFor="naturalLanguageText" className={"form-label"}>Natural language text:</label>
                    <textarea type="text" id={"naturalLanguageText"} className={"form-control"} rows={3} disabled={generatingSql} placeholder={"Natural language text"} value={naturalLanguageText} required={true} onChange={onChange}/>
                </div>
                <div className={"mb-3"}>
                    <p id={"naturalLanguageTextWarning"} className={"text-warning"}>{naturalLanguageTextWarning}</p>
                </div>
                <div className={"mb-5"}>
                    <button type={"button"} className={"btn btn-secondary mt-2"} onClick={generateSql} disabled={generatingSql || naturalLanguageText === ""}>{generatingSql ? <SmallSpinner />: 'Generate'}</button>
                    <p id={"errorsGenerate"} className={"text-danger"}>{errorGenerateSQL}</p>
                </div>
                <div className={"mb-3"}>
                    <div className={"row align-items-center"}>
                        <label htmlFor="generatedSql" className={"form-label col-auto"}>SQL result:</label>
                        <button className={"btn btn-primary col-auto"} onClick={(evt) => {
                            evt.preventDefault();
                            copySqlGeneratedToClipboard(sqlGenerated)}
                        }>{copiedSqlGenerated ? "Copied!" : "Copy to clipboard"}</button>
                    </div>
                    <textarea type="text" id={"generatedSql"} className={"form-control bg-white"} rows={15} value={sqlGenerated} readOnly={true} required={true} onChange={onChange}/>
                </div>
                <div className={"mb-3"}>
                    <div className={"row align-items-center"}>
                        <label htmlFor="tweakedSQL" className={"form-label col-auto"}>If the generated SQL is bad, please improve it (leave this empty otherwise):</label>
                        <button className={"btn btn-primary col-auto"} onClick={(evt) => {
                            evt.preventDefault();
                            copyTweakedSqlToClipboard(tweakedSQL)}
                        }>{copiedTweakedSql ? "Copied!" : "Copy to clipboard"}</button>
                    </div>
                    <textarea type="text" id={"tweakedSQL"} className={"form-control bg-white"} value={tweakedSQL} rows={15} disabled={sqlGenerated === ""} onChange={onChange}/>
                </div>
                <button type={"submit"} className="btn btn-success" disabled={submittingScore || sqlGenerated === "" || generatingSql}>{submittingScore ? <SmallSpinner />: "Submit"}</button>
                <span id={"errorsSubmitting"} className={"text-danger"}>{errorSubmit}</span>
            </form>
        </PageDiv>
    );
}

export default GenerateSqlPage;

