import EditText2sqlConversion from "./EditText2sqlConversion";
import {useState} from "react";

function Text2sqlConversionRow({text2sqlConversion}) {
    const [row, setRow] = useState(text2sqlConversion);

    return (
        <tr>
            <td>{row.database}</td>
            <td>{row.schema}</td>
            <td>{row.naturalLanguageText}</td>
            <td>{row.humanEditedSql !== "" ? row.humanEditedSql : row.sqlGenerated}</td>
            <td>
                <div className={"d-flex gap-2"}>
                    <EditText2sqlConversion row={row} setRow={setRow}/>
                </div>
            </td>
        </tr>
    );
}

export default Text2sqlConversionRow;

