import {useContext} from "react";
import {authContext} from "../context/auth";
import {Navigate, Outlet} from "react-router-dom";
import Navbar from "./Navbar";

export default function ProtectedRoute() {
    const {token} = useContext(authContext);

    if (!token) {
        return  <Navigate to={'/signIn'} />;
    }
    else {
        //TODO validate token
        return (
            <>
                <Navbar />
                <Outlet />
            </>

        );
    }
};
