function Text2sqlConversionsFilters({filters, setFilters, getText2sqlConversions, loading, pageInfo, setLoading, getNrQueries, setPageInfo}) {
    async function onSubmit(evt) {
        evt.preventDefault();
        setLoading(true);
        const nrQueries = (await getNrQueries()).count;
        let maxOffset = Math.floor(nrQueries / pageInfo.limit) * pageInfo.limit;
        if (maxOffset === nrQueries) {
            maxOffset = maxOffset - pageInfo.limit;
        }
        const pgInfo = {
            ...pageInfo,
            offset: 0,
            hasNextPage: nrQueries > pageInfo.limit,
            hasPreviousPage: false,
            maxOffset: maxOffset
        }
        setPageInfo(pgInfo);
        setLoading(false);
        getText2sqlConversions(pgInfo);
    }

    return (
        <form onSubmit={onSubmit}>
            Filter by:
            <textarea className={"form-control mb-1"} placeholder={"Natural language text"} onChange={(evt) => {
                setFilters({
                    ...filters,
                    naturalLanguageText: evt.target.value
                });
            }}/>
            <button type={"submit"} className={"btn btn-success mb-3"} disabled={loading}>Filter</button>
        </form>
    );
}

export default Text2sqlConversionsFilters;

