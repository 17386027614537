import {NavLink} from "react-router-dom";
import {useContext} from "react";
import {authContext} from "../context/auth";

function Navbar() {
    const activeClassName = "nav-link active";
    const {setUser, setToken} = useContext(authContext);

    return (
        <div className={"navbar navbar-expand-lg navbar-dark bg-dark"}>
            <div className={"container-fluid"}>
                <a href="#" className={"navbar-brand"}>Text2SQL</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink className={({isActive}) => {
                                return isActive ? activeClassName : "nav-link";
                            }} to="/generateSQL">Generate SQL</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({isActive}) => {
                                return isActive ? activeClassName : "nav-link";
                            }} to="/reviewWork">Review work</NavLink>
                        </li>
                    </ul>
                    <ul className={"navbar-nav mb-2 mb-lg-0"}>
                        <li className="nav-item dropdown">
                            <a className={"nav-link dropdown-toggle"} href="#" id="navbarProfileDropdown" role="button"
                               data-bs-toggle="dropdown" aria-expanded="false">
                                My profile
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarProfileDropdown">
                                <li>
                                    <a className="dropdown-item" href={"#"} onClick={(evt) => {
                                        localStorage.removeItem('token');
                                        setUser({});
                                        setToken(null);
                                    }}>Sign out</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Navbar;

