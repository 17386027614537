import {useContext, useEffect, useState} from "react";
import { authContext } from "../../../context/auth";
import Error from "../../Alerts/Error";
import Spinner from "../../Spinner";

function EditText2sqlConversion ({row: initialText2sqlConversion, setRow}) {
    const {token} = useContext(authContext);
    const [text2sqlConversion, setText2sqlConversion] = useState(initialText2sqlConversion);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        setError(false);
        setText2sqlConversion(initialText2sqlConversion);
    }, [initialText2sqlConversion]);

    async function onSubmit(evt) {
        evt.preventDefault();
        setError(false);
        if (text2sqlConversion.likertScore.toString() === "3" && text2sqlConversion.sqlGenerated === text2sqlConversion.humanEditedSql) {
            setError("Tweaked SQL is the same as the Result SQL!");
        }
        else {
            setLoading(true);
            delete text2sqlConversion.stamp;
            const response = await fetch(`${process.env.REACT_APP_endPoint}/text2sqlConversions`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(text2sqlConversion)
            });

            if (response.status === 200) {
                const jsonData = await response.json();
                delete jsonData.stamp;
                setRow(jsonData);
                setText2sqlConversion(jsonData);
            }
            else if (response.status === 400) {
                setError("Query does not return any row!");
            }
            else {
                console.error("Error while updating resource");
                setError("Error while updating resource");
            }
            setLoading(false);
        }
    }

    function onChange(evt) {
        switch (evt.target.type) {
            case "checkbox":
                setText2sqlConversion({
                    ...text2sqlConversion,
                    [evt.target.name]: evt.target.checked
                });
                break;
            default:
                setText2sqlConversion({
                    ...text2sqlConversion,
                    [evt.target.name]: evt.target.value
                });
        }
    }

    return (
        <>
            <button className={"btn btn-warning btn-sm"} data-bs-toggle={"modal"} data-bs-target={"#editText2sqlConversionModal" + text2sqlConversion.id}>Edit</button>
            <div className={"modal fade"} id={"editText2sqlConversionModal" + text2sqlConversion.id} tabIndex="-1" aria-labelledby={"editText2sqlConversionModalLabel" + text2sqlConversion.id} aria-hidden="true">
                <div className={"modal-dialog modal-lg"}>
                    <div className={"modal-content"}>
                        <form onSubmit={onSubmit}>
                            <div className={"modal-header"}>
                                <h5 className={"modal-title"} id={"editText2sqlConversionModalLabel" + text2sqlConversion.id}>Edit text2sqlConversion</h5>
                                <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} />
                            </div>
                            <div className={"modal-body"}>
                                <div className={"mb-3"}>
                                    <label htmlFor={"database" + text2sqlConversion.id} className={"form-label"}>Database:</label>
                                    <input name={"database"} type="text" id={"database" + text2sqlConversion.id} className={"form-control"} placeholder={"database"} required={true} readOnly={true} value={text2sqlConversion.database} onChange={onChange}/>
                                </div>
                                <div className={"mb-3"}>
                                    <label htmlFor={"schema" + text2sqlConversion.id} className={"form-label"}>Schema:</label>
                                    <input name={"schema"} type="text" id={"schema" + text2sqlConversion.id} className={"form-control"} placeholder={"schema"} required={true} readOnly={true} value={text2sqlConversion.schema} onChange={onChange}/>
                                </div>
                                <div className={"mb-3"}>
                                    <label htmlFor={"naturalLanguageText" + text2sqlConversion.id} className={"form-label"}>Natural language text:</label>
                                    <textarea name={"naturalLanguageText"} id={"naturalLanguageText" + text2sqlConversion.id} className={"form-control"} required={true} value={text2sqlConversion.naturalLanguageText} rows={3} onChange={onChange}/>
                                </div>
                                <div className={"mb-3"}>
                                    <label htmlFor={"humanEditedSql" + text2sqlConversion.id} className={"form-label"}>SQL result:</label>
                                    <textarea name={"humanEditedSql"} id={"humanEditedSql" + text2sqlConversion.id} className={"form-control"} required={true} value={text2sqlConversion.humanEditedSql !== "" ? text2sqlConversion.humanEditedSql : text2sqlConversion.sqlGenerated} rows={10} onChange={onChange}/>
                                </div>
                            </div>
                            {error && <Error message={error} />}
                            <div className={"modal-footer justify-content-between"}>
                                <button type={"button"} className={"btn btn-secondary"} data-bs-dismiss={"modal"}>Close</button>
                                <button type={"submit"} className={"btn btn-warning"} disabled={loading}>{loading ? <Spinner />: 'Save'}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditText2sqlConversion;

