import {useContext, useState, useEffect} from "react";
import {authContext} from "../../../context/auth";
import Spinner from "../../Spinner";
import Text2sqlConversionRow from "./Text2sqlConversionRow";
import PageDiv from "../../PageDiv";
import Error from "../../Alerts/Error";
import Pagination from "../../Pagination";
import Text2sqlConversionsFilters from "./Text2sqlConversionsFilters";


export default function Text2sqlConversions() {
    const {token} = useContext(authContext);
    const [text2sqlConversions, setText2sqlConversions] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [pageInfo, setPageInfo] = useState({
        limit: 20,
        offset: 0,
        hasPreviousPage: false
    });
    const [filters, setFilters] = useState({});

    async function getNrQueries() {
        let url = `${process.env.REACT_APP_endPoint}/text2sqlConversions/count`

        if (filters.naturalLanguageText) {
            url += `?naturalLanguageText=${filters.naturalLanguageText}`;
        }

        const response = await fetch(url, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        if (response.status !== 200) {
            setError("Failed to fetch the number of queries already done.");
            return null
        }
        else {
            const result = await response.json();
            console.log("Result:", result);
            return result
        }
    }

    async function getText2sqlConversions(pgInfo) {
        setLoading(true);
        let url = `${process.env.REACT_APP_endPoint}/text2sqlConversions?offset=${pgInfo.offset}&limit=${pgInfo.limit}`
        if (filters.naturalLanguageText) {
            url += `&naturalLanguageText=${filters.naturalLanguageText}`;
        }

        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });

        if (response.status !== 200) {
            console.error("Error while fetching your previous work.");
            setError("Error while fetching your previous work.");
        }
        else {
            const jsonData = await response.json();
            setText2sqlConversions(jsonData.rows);
        }
        setLoading(false);
    }

    useEffect(() => {
        (async () => {
            setLoading(true);
            const nrQueries = (await getNrQueries()).count;
            let maxOffset = Math.floor(nrQueries / pageInfo.limit) * pageInfo.limit;
            if (maxOffset === nrQueries) {
                maxOffset = maxOffset - pageInfo.limit;
            }
            const pgInfo = {
                ...pageInfo,
                hasNextPage: nrQueries > pageInfo.offset + pageInfo.limit,
                hasPreviousPage: pageInfo.offset > 0,
                maxOffset: maxOffset
            }
            setPageInfo(pgInfo);
            setLoading(false);
            getText2sqlConversions(pgInfo);
        })()
    }, []);

    async function setLimit(limit) {
        setLoading(true);
        const nrQueries = (await getNrQueries()).count;
        let maxOffset = Math.floor(nrQueries / limit) * limit;
        if (maxOffset === nrQueries) {
            maxOffset = maxOffset - limit;
        }
        const pgInfo = {
            ...pageInfo,
            offset: 0,
            limit: limit,
            hasNextPage: nrQueries > pageInfo.offset + limit,
            hasPreviousPage: pageInfo.offset > 0,
            maxOffset: maxOffset
        }
        setPageInfo(pgInfo);
        setLoading(false);
        getText2sqlConversions(pgInfo);
    }

    async function setOffset(offset) {
        setLoading(true);
        const nrQueries = (await getNrQueries()).count;
        let maxOffset = Math.floor(nrQueries / pageInfo.limit) * pageInfo.limit;
        if (maxOffset === nrQueries) {
            maxOffset = maxOffset - pageInfo.limit;
        }
        const pgInfo = {
            ...pageInfo,
            offset: offset,
            hasNextPage: nrQueries > offset + pageInfo.limit,
            hasPreviousPage: offset > 0,
            maxOffset: maxOffset
        }
        setPageInfo(pgInfo);
        setLoading(false);
        getText2sqlConversions(pgInfo);
    }

    return (
        <PageDiv>
        {error && <Error message={error} />}
        <div className={"table-responsive overflow-auto"}>
            <Text2sqlConversionsFilters disabled={loading} loading={loading} filters={filters} setFilters={setFilters} getText2sqlConversions={getText2sqlConversions} pageInfo={pageInfo} setLoading={setLoading} getNrQueries={getNrQueries} setPageInfo={setPageInfo} />
            <Pagination disabled={loading} limit={pageInfo.limit} offset={pageInfo.offset} hasNextPage={pageInfo.hasNextPage} hasPreviousPage={pageInfo.hasPreviousPage} setLimit={setLimit} setOffset={setOffset} maxOffset={pageInfo.maxOffset}/>
            <table className={"table table-striped mt-2 table-bordered"} id={"text2sqlConversionsTable"}>
                <thead className={"table-light"}>
                <tr>
                    <th>Database</th>
                    <th>Schema</th>
                    <th>Natural language text</th>
                    <th>SQL result</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>

                    {loading && <tr><td colSpan={6}><Spinner /></td></tr>}
                        {text2sqlConversions.map(value => {
                            return <Text2sqlConversionRow text2sqlConversion={value} key={value.id} />;
                        })}

                </tbody>
            </table>
        </div>
        </PageDiv>
    );

}
