function Pagination({limit, offset, hasNextPage, hasPreviousPage, setLimit, setOffset, maxOffset}) {
    function goToOffset(evt, offsetToGoTo) {
        evt.preventDefault();
        setOffset(offsetToGoTo);
    }

    return (
        <div className={"d-flex justify-content-between align-items-center"}>
            <div className={"d-flex"}>
                <div>
                    <ul className={"pagination m-0"}>
                        <li className={"page-item" + (!hasPreviousPage ? " disabled" : "")}>
                            <a className={"page-link"} aria-disabled={!hasPreviousPage} onClick={(evt) => {
                                goToOffset(evt, 0);
                            }}>&lt;&lt;</a>
                        </li>
                        <li className={"page-item" + (!hasPreviousPage ? " disabled" : "")}>
                            <a className={"page-link"} aria-disabled={!hasPreviousPage} onClick={(evt) => {
                                goToOffset(evt, offset - limit);
                            }}>&lt;</a>
                        </li>
                    </ul>
                </div>
                <div className={"ms-2"}>
                    <select className={"form-select"} value={limit} onChange={(evt) => {
                        setLimit(parseInt(evt.target.value));
                    }}>
                        <option value="" disabled={true}>Select a quantity</option>
                        <option value={2}>2</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                    </select>
                </div>
            </div>
            <div>
                Page {Math.floor(offset/limit) + 1}/{Math.floor(maxOffset / limit) + 1}
            </div>
            <div className={"d-flex"}>
                <div className={"me-2"}>
                    <select className={"form-select"} value={limit} onChange={(evt) => {
                        setLimit(parseInt(evt.target.value));
                    }}>
                        <option value="" disabled={true}>Select a quantity</option>
                        <option value={2}>2</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                    </select>
                </div>
                <div>
                    <ul className={"pagination m-0"}>
                        <li className={"page-item" + (!hasNextPage ? " disabled" : "")}>
                            <a className={"page-link"} aria-disabled={!hasNextPage} onClick={(evt) => {
                                goToOffset(evt, offset + limit);
                            }}>&gt;</a>
                        </li>
                        <li className={"page-item" + (!hasNextPage ? " disabled" : "")}>
                            <a className={"page-link"} aria-disabled={!hasNextPage} onClick={(evt) => {
                                goToOffset(evt, maxOffset);
                            }}>&gt;&gt;</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Pagination;

