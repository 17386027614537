import GenerateSqlPage from "./components/pages/GenerateSqlPage";
import Background from "./components/Background";
import SignIn from "./components/pages/SignIn";
import ProtectedRoute from "./components/ProtectedRoute";
import { authContext, AuthProvider } from "./context/auth";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useContext } from "react";
import Spinner from "./components/Spinner";
import Text2sqlConversions from "./components/pages/reviewWork/Text2sqlConversions";


function AppComponent() {
    const {loading} = useContext(authContext);

    if (loading) {
        return (<Spinner/>);
    }
    else {
      return (
        <Router>
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route path={"/generateSQL"} element={<GenerateSqlPage />}/>
              <Route path={"/reviewWork"} element={<Text2sqlConversions />}/>
            </Route>
            <Route path={'/signIn'} element={<><Background /><SignIn /></>}></Route>
            <Route path="*" element={<Navigate to="/signIn" />} />
          </Routes>
        </Router>
      );
    }
}


function App() {
  return (
    <AuthProvider>
      <AppComponent />
    </AuthProvider>
  );
}

export default App;
