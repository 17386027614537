import {useContext, useState} from "react";
import {authContext} from "../../context/auth";
import {Navigate} from "react-router-dom";
import Spinner from "../Spinner";
import Error from "../Alerts/Error";
import SmallSpinner from "../SmallSpinner";


export default function SignIn() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const {setUser, setToken, token} = useContext(authContext);

    async function onSubmit(evt) {
        evt.preventDefault();

        try {
            setLoading(true);
            setError(null);

            const response = await fetch(process.env.REACT_APP_endPoint + "/auth/signIn", {
                method: "POST",
                body: JSON.stringify({
                    email,
                    password
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                const {user, token} = await response.json();
                setUser(user);
                setToken(token);
                localStorage.setItem("token", token);
            }
            else {
                const {error} = await response.json();
                if (error) {
                    setError(error);
                }
            }
        }
        catch (e) {
            console.error(e);
            setError("Failed to reach server.");
        }
        finally {
            setLoading(false);
        }
    }

    if (token) {
        return <Navigate to={'/generateSQL'} />;
    }
    return (
        <form onSubmit={onSubmit} className={"shadow-sm border p-3 bg-white m-3 container mx-auto"} >
            {error && <Error message={error} />}

            <div className={"mb-3"}>
                <label htmlFor="email" className={"form-label"}>Email address:</label>
                <input type="email" id={"email"} className={"form-control"} required={true} value={email} onChange={evt => {
                    setEmail(evt.target.value);
                }}/>
            </div>
            <div className="mb-3">
                <label htmlFor="password" className={"form-label"}>Password:</label>
                <input type="password" id={"password"} className={"form-control"} required={true} value={password} onChange={evt => {
                    setPassword(evt.target.value);
                }}/>
            </div>
            <button type={"submit"} className={"btn btn-success w-100 mt-3"} disabled={loading}>Sign in {loading && <SmallSpinner small={true}/>}</button>
        </form>
    );
}
