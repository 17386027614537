import {createContext, useEffect, useState} from "react";

export const authContext = createContext({
    token: null,
    setToken: (token) => {},
    user: {},
    setUser: (user) => {}
});

export function AuthProvider({children}) {
    const [token, setToken] = useState(null);
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            const tokenFromLocalStorage = localStorage.getItem('token');
            if (tokenFromLocalStorage) {
                setLoading(true);
                const response = await fetch(`${process.env.REACT_APP_endPoint}/user`, {
                    headers: {
                        "Authorization": `Bearer ${tokenFromLocalStorage}`
                    }
                });
                if (response.status !== 200) {
                    setToken(null);
                }
                else {
                    const u = await response.json();
                    setUser(u);
                    setToken(tokenFromLocalStorage);
                }
                setLoading(false);
            }
        })();
    }, []);

    return (
        <authContext.Provider value={{
            loading,
            token,
            user,
            setToken,
            setUser
        }}>
            {children}
        </authContext.Provider>
    );
}
